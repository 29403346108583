import { createUseStyles } from "react-jss";

export const useEulaStyles = createUseStyles((theme) => ({
  mainContainer: {
    background: theme.palette.background.surfaceElevation1,
    padding: '30px 50px',
    minWidth: 1200
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 4,
    marginBottom: 4
  },
  brandLogo: {
    height: 24
  },
  iframeContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: "20px 0",
    flexDirection: "column"
  },
  acknowledgeContainer: {
    marginTop: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: "white"
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    columnGap: 10
  }
}))